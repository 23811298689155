<template>
  <div>
    <ba-dialog
      btn-color="success"
      btn-name="Create API Key"
      small
      btnTooltip="Create API Key"
      title="Create API Key"
      :show="dialog"
      v-on:show-dialog="dialog = true"
    >
      <v-container fluid grid-list-xl>
        <v-layout wrap align-center>
          <v-flex xs6 sm6 md6 v-if="state === 'create'">
            <v-form ref="invite-form" v-model="valid">
              <v-text-field label="Description" v-model="description" :rules="[rules.required]"></v-text-field>
              <v-layout row wrap>
                <v-flex xs12><h4>Permissions</h4></v-flex>
                <v-flex xs3>
                  <v-checkbox class="ma-0 pa-0" v-model="permReadAll" label="Read All"></v-checkbox>
                </v-flex>
                <v-flex xs3>
                  <v-checkbox class="ma-0 pa-0" v-model="permWriteAll" label="Write All"></v-checkbox>
                </v-flex>
                <v-flex xs12>
                  <v-btn @click="save">Create API Key</v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
          <v-flex xs6 sm6 md6 v-if="state === 'view'">
            <v-layout row wrap>
              <v-flex xs12>
                Please save the Client Secret below, it will be inaccessible after you close this window
              </v-flex>
              <v-flex xs2> Client ID </v-flex>
              <v-flex xs10>
                {{ newCredentials.clientId }}
              </v-flex>
              <v-flex xs2> Client Secret </v-flex>
              <v-flex xs10>
                {{ newCredentials.clientSecret }}
              </v-flex>
              <v-flex xs12>
                <v-btn @click="close">I've recorded this information</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </ba-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: [],
  data() {
    return {
      description: '',
      permReadAll: true,
      permWriteAll: false,
      valid: false,
      dialog: false,
      state: 'create',
      newCredentials: {
        clientId: '',
        clientSecret: '',
      },
      rules: {
        required: (v) => !!v || 'Field is required',
      },
    };
  },
  computed: {
    canSave() {
      return this.permReadAll === true || this.permWriteAll === true;
      // return true; // return this.name !== "" && this.email !== "" && this.email.includes("@"); // TODO fix logic
    },
  },
  watch: {},
  methods: {
    async save() {
      const perms = [];
      if (this.permReadAll) {
        perms.push('ReadAll');
      }

      if (this.permWriteAll) {
        perms.push('WriteAll');
      }

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        description: this.description,
        permissions: perms,
      };

      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $description: String!, $permissions: [AuthTokenPermissions]!) {
            createAuthToken(orgId: $orgId, description: $description, permissions: $permissions) {
              clientId
              clientSecret
            }
          }
        `,
        // Parameters
        variables: vars,
      });

      if (resp.errors) {
        this.showErrorSnackbar('Problem creating token: ' + resp.errors.join('<br />'));
      }

      if (resp.data) {
        if (resp.data.createAuthToken) {
          this.showSuccessSnackbar('Token Created!');
          this.description = '';
          this.permWriteAll = false;
          this.permReadAll = true;
          this.state = 'view';
          this.newCredentials = resp.data.createAuthToken;

          this.$emit('complete');
        } else {
          this.showErrorSnackbar('Problem creating API Key: ' + resp.data.createAuthToken.errors.join('<br />'));
        }
      }
    },
    close() {
      this.newCredentials = {
        clientId: '',
        clientSecret: '',
      };
      this.state = 'create';
      this.dialog = false;
    },
  },
};
</script>
