/* tslint:disable */
/* eslint-disable */
/**
 * Reconciliation Service API
 * This services provides an API to manager reconciliations and reconciliation templates.
 *
 * The version of the OpenAPI document: v1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface CoreSvcAdminhdlGetOutput
 */
export interface CoreSvcAdminhdlGetOutput {
  /**
   *
   * @type {string}
   * @memberof CoreSvcAdminhdlGetOutput
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcAdminhdlGetOutput
   */
  services: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcAdminhdlGetOutput
   */
  status: string;
}
/**
 *
 * @export
 * @interface CoreSvcDtosListOrgDTO
 */
export interface CoreSvcDtosListOrgDTO {
  /**
   *
   * @type {number}
   * @memberof CoreSvcDtosListOrgDTO
   */
  customerType?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcDtosListOrgDTO
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof CoreSvcDtosListOrgDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcDtosListOrgDTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcDtosListOrgDTO
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcDtosListOrgDTO
   */
  updatedBy?: string;
}
/**
 *
 * @export
 * @interface CoreSvcHealthhdlGetOutput
 */
export interface CoreSvcHealthhdlGetOutput {
  /**
   *
   * @type {string}
   * @memberof CoreSvcHealthhdlGetOutput
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcHealthhdlGetOutput
   */
  services: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcHealthhdlGetOutput
   */
  status: string;
}
/**
 *
 * @export
 * @interface CoreSvcOrgAccountingConfig
 */
export interface CoreSvcOrgAccountingConfig {
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgAccountingConfig
   */
  allowTxnInference?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgAccountingConfig
   */
  collapseAcrossWallets?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAccountingConfig
   */
  defaultAccountsPayableCategoryId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAccountingConfig
   */
  defaultAccountsReceivableCategoryId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAccountingConfig
   */
  defaultFeeCategoryId?: string | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CoreSvcOrgAccountingConfig
   */
  defaultFeeCategoryIds?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgAccountingConfig
   */
  multiCurrency?: boolean | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CoreSvcOrgAccountingConfig
   */
  networkContactIds?: { [key: string]: string };
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgAccountingConfig
   */
  separateFeeTransactions?: boolean | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgAccountingSetup
 */
export interface CoreSvcOrgAccountingSetup {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAccountingSetup
   */
  balanceExportReportId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAccountingSetup
   */
  balanceExportStartDate?: string | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CoreSvcOrgAccountingSetup
   */
  flags?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgAccountingSetup
   */
  initialSyncMethod?: number;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgAccountingSetup
   */
  isFinished?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgAccountingSetup
   */
  isReconciled?: boolean | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgAllowDeployedBlockHeightRule
 */
export interface CoreSvcOrgAllowDeployedBlockHeightRule {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAllowDeployedBlockHeightRule
   */
  blockNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAllowDeployedBlockHeightRule
   */
  evaluator?: string;
}
/**
 *
 * @export
 * @interface CoreSvcOrgAssetValuationStrategy
 */
export interface CoreSvcOrgAssetValuationStrategy {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAssetValuationStrategy
   */
  assetValuationStrategy?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgAssetValuationStrategy
   */
  currencyId?: string;
}
/**
 *
 * @export
 * @interface CoreSvcOrgClusterSettings
 */
export interface CoreSvcOrgClusterSettings {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgClusterSettings
   */
  primary?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof CoreSvcOrgClusterSettings
   */
  replicas?: { [key: string]: object };
}
/**
 *
 * @export
 * @interface CoreSvcOrgDisabledDeployedByBlockHeightRule
 */
export interface CoreSvcOrgDisabledDeployedByBlockHeightRule {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgDisabledDeployedByBlockHeightRule
   */
  blockNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgDisabledDeployedByBlockHeightRule
   */
  evaluator?: string;
}
/**
 *
 * @export
 * @interface CoreSvcOrgDisplayConfig
 */
export interface CoreSvcOrgDisplayConfig {
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgDisplayConfig
   */
  useOrgTimezone?: boolean;
}
/**
 *
 * @export
 * @interface CoreSvcOrgEnterprisePaymentSettings
 */
export interface CoreSvcOrgEnterprisePaymentSettings {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  custodian?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  defaultForexCategoryId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  enabledCryptoCurrencies?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  enabledFiats?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  kycProvider?: string;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  locked?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  pricingSources?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  pricingTimezone?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  remittanceContactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgEnterprisePaymentSettings
   */
  remittanceSenderName?: string | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgGainLossConfig
 */
export interface CoreSvcOrgGainLossConfig {
  /**
   *
   * @type {Array<CoreSvcOrgAssetValuationStrategy>}
   * @memberof CoreSvcOrgGainLossConfig
   */
  assetValuationStrategies?: Array<CoreSvcOrgAssetValuationStrategy>;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgGainLossConfig
   */
  capitalizeTradingFees?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgGainLossConfig
   */
  costBasisCarryForwardAcquiredSide?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgGainLossConfig
   */
  defaultValuationStrategy?: string | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgGainLossConfig
   */
  engineVersion?: number | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgGainLossConfig
   */
  impairmentMethodology?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgGainLossConfig
   */
  impairmentWhitelist?: Array<string>;
  /**
   *
   * @type {CoreSvcOrgInventoryMappingRule}
   * @memberof CoreSvcOrgGainLossConfig
   */
  inventoryMappingRule?: CoreSvcOrgInventoryMappingRule;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgGainLossConfig
   */
  processAcquisitionsBeforeDisposals?: boolean | null;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof CoreSvcOrgGainLossConfig
   */
  wrappingTreatments?: { [key: string]: Array<string> } | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgInventoryMappingRule
 */
export interface CoreSvcOrgInventoryMappingRule {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInventoryMappingRule
   */
  inventoryGroupId?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInventoryMappingRule
   */
  type?: string;
}
/**
 *
 * @export
 * @interface CoreSvcOrgInvoicingSettings
 */
export interface CoreSvcOrgInvoicingSettings {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  bitcoinAddress?: string | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  bitcoinCurrentXPubIndex?: number | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  bitcoinXPub?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  bitcoinXPubPath?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  ethAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  fromEmail?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  fromName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  usdcAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  usdtAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgInvoicingSettings
   */
  zecAddress?: string | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgOrgAdminConfig
 */
export interface CoreSvcOrgOrgAdminConfig {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgOrgAdminConfig
   */
  releaseChannel?: string | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgOrgConfig
 */
export interface CoreSvcOrgOrgConfig {
  /**
   *
   * @type {CoreSvcOrgOrgAdminConfig}
   * @memberof CoreSvcOrgOrgConfig
   */
  adminConfig?: CoreSvcOrgOrgAdminConfig;
  /**
   *
   * @type {CoreSvcOrgOrgStaticConfig}
   * @memberof CoreSvcOrgOrgConfig
   */
  staticConfig?: CoreSvcOrgOrgStaticConfig;
  /**
   *
   * @type {CoreSvcOrgOrgSystemConfig}
   * @memberof CoreSvcOrgOrgConfig
   */
  systemConfig?: CoreSvcOrgOrgSystemConfig;
}
/**
 *
 * @export
 * @interface CoreSvcOrgOrgPersistenceSettings
 */
export interface CoreSvcOrgOrgPersistenceSettings {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgOrgPersistenceSettings
   */
  databaseInstance?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgOrgPersistenceSettings
   */
  databaseName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgOrgPersistenceSettings
   */
  databaseProject?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgOrgPersistenceSettings
   */
  provider?: string;
}
/**
 *
 * @export
 * @interface CoreSvcOrgOrgStaticConfig
 */
export interface CoreSvcOrgOrgStaticConfig {
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgOrgStaticConfig
   */
  eventSourced?: boolean;
}
/**
 *
 * @export
 * @interface CoreSvcOrgOrgSystemConfig
 */
export interface CoreSvcOrgOrgSystemConfig {
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgOrgSystemConfig
   */
  showReleaseChannels?: boolean;
}
/**
 *
 * @export
 * @interface CoreSvcOrgPricingSettings
 */
export interface CoreSvcOrgPricingSettings {
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgPricingSettings
   */
  preferredExchange?: number | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgSsoSettings
 */
export interface CoreSvcOrgSsoSettings {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgSsoSettings
   */
  defaultRoleId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgSsoSettings
   */
  idpCertificate?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgSsoSettings
   */
  idpIssuer?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgSsoSettings
   */
  idpSignOnUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgSsoSettings
   */
  requireSso?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgSsoSettings
   */
  use?: boolean | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgSubscriptionDetails
 */
export interface CoreSvcOrgSubscriptionDetails {
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgSubscriptionDetails
   */
  accountingLicenses?: number;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgSubscriptionDetails
   */
  customerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgSubscriptionDetails
   */
  subscriptionId?: string | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgSubscriptionDetails
   */
  walletLicenses?: number;
}
/**
 *
 * @export
 * @interface CoreSvcOrgTokenFilteringRulesConfig
 */
export interface CoreSvcOrgTokenFilteringRulesConfig {
  /**
   *
   * @type {Array<CoreSvcOrgAllowDeployedBlockHeightRule>}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  allowDeployedBlockHeightRules?: Array<CoreSvcOrgAllowDeployedBlockHeightRule>;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  allowedContractAddresses?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  allowedDeployedByAddresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  disabledContractAddresses?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  disabledDeployedByAddresses?: Array<string>;
  /**
   *
   * @type {Array<CoreSvcOrgDisabledDeployedByBlockHeightRule>}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  disabledDeployedByBlockHeightRules?: Array<CoreSvcOrgDisabledDeployedByBlockHeightRule>;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  erc20FilterThreshold?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  filteredAddresses?: Array<string>;
  /**
   *
   * @type {Array<CoreSvcOrgWalletSyncPreference>}
   * @memberof CoreSvcOrgTokenFilteringRulesConfig
   */
  walletSyncPreferences?: Array<CoreSvcOrgWalletSyncPreference>;
}
/**
 *
 * @export
 * @interface CoreSvcOrgWalletSyncPreference
 */
export interface CoreSvcOrgWalletSyncPreference {
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgWalletSyncPreference
   */
  syncAllLogEvents?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgWalletSyncPreference
   */
  walletId?: string;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlBulkActionResult
 */
export interface CoreSvcOrgshdlBulkActionResult {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlBulkActionResult
   */
  message?: string | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlBulkActionResult
   */
  status: number;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlBulkUpdateOrgsInput
 */
export interface CoreSvcOrgshdlBulkUpdateOrgsInput {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CoreSvcOrgshdlBulkUpdateOrgsInput
   */
  updates?: { [key: string]: string } | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlBulkUpdateOutput
 */
export interface CoreSvcOrgshdlBulkUpdateOutput {
  /**
   *
   * @type {{ [key: string]: CoreSvcOrgshdlBulkActionResult; }}
   * @memberof CoreSvcOrgshdlBulkUpdateOutput
   */
  results?: { [key: string]: CoreSvcOrgshdlBulkActionResult } | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlGetOrgOutput
 */
export interface CoreSvcOrgshdlGetOrgOutput {
  /**
   *
   * @type {CoreSvcOrgAccountingConfig}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  accountingConfig?: CoreSvcOrgAccountingConfig;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  accountingInventoryGroupId?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  accountingInventoryGroupIds?: Array<string> | null;
  /**
   *
   * @type {CoreSvcOrgAccountingSetup}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  accountingSetup?: CoreSvcOrgAccountingSetup;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  baseCurrency?: number | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  billingOrgId?: string | null;
  /**
   *
   * @type {CoreSvcOrgClusterSettings}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  cluster?: CoreSvcOrgClusterSettings;
  /**
   *
   * @type {CoreSvcOrgOrgConfig}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  config?: CoreSvcOrgOrgConfig;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  created?: number;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  createdByPrincipalId?: string;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  customerType?: number | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  defaultFeeCategoryId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  defiGroupWalletId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  deleted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  disabled?: boolean | null;
  /**
   *
   * @type {CoreSvcOrgDisplayConfig}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  displayConfig?: CoreSvcOrgDisplayConfig;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  engineVersion?: number | null;
  /**
   *
   * @type {CoreSvcOrgEnterprisePaymentSettings}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  enterprisePaymentSettings?: CoreSvcOrgEnterprisePaymentSettings;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  flags?: { [key: string]: string } | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  hardCloseDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  id?: string | null;
  /**
   *
   * @type {CoreSvcOrgInvoicingSettings}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  invoicingSettings?: CoreSvcOrgInvoicingSettings;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  logoFileName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  networkContactIds?: { [key: string]: string } | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  parentOrgId?: string | null;
  /**
   *
   * @type {CoreSvcOrgOrgPersistenceSettings}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  persistenceSettings?: CoreSvcOrgOrgPersistenceSettings;
  /**
   *
   * @type {CoreSvcOrgPricingSettings}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  pricingSettings?: CoreSvcOrgPricingSettings;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  provisionedAccountingUsers?: number | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  provisionedUsers?: number | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  provisionedWalletUsers?: number | null;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  roles?: { [key: string]: number } | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  scimTokenHash?: string | null;
  /**
   *
   * @type {CoreSvcOrgSsoSettings}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  sso?: CoreSvcOrgSsoSettings;
  /**
   *
   * @type {CoreSvcOrgSubscriptionDetails}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  subscriptionDetails?: CoreSvcOrgSubscriptionDetails;
  /**
   *
   * @type {CoreSvcOrgGainLossConfig}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  taxConfig?: CoreSvcOrgGainLossConfig;
  /**
   *
   * @type {object}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  taxStrategy?: object;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  testOrg?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  timezone?: string;
  /**
   *
   * @type {CoreSvcOrgTokenFilteringRulesConfig}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  tokenFilteringConfig?: CoreSvcOrgTokenFilteringRulesConfig;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  trialExpiry?: string | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  updated?: number;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  updatedByPrincipalId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgshdlGetOrgOutput
   */
  walletIds?: Array<string>;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlGetOutput
 */
export interface CoreSvcOrgshdlGetOutput {
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOutput
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOutput
   */
  services: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetOutput
   */
  status: string;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlGetShortListOutput
 */
export interface CoreSvcOrgshdlGetShortListOutput {
  /**
   *
   * @type {Array<CoreSvcDtosListOrgDTO>}
   * @memberof CoreSvcOrgshdlGetShortListOutput
   */
  items?: Array<CoreSvcDtosListOrgDTO> | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlGetShortListOutput
   */
  nextPageToken?: string | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlUpdateOrgAdminSettingsInput
 */
export interface CoreSvcOrgshdlUpdateOrgAdminSettingsInput {
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgshdlUpdateOrgAdminSettingsInput
   */
  capitalizeTradingFees?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlUpdateOrgAdminSettingsInput
   */
  engineVersion?: number | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlUpdateOrgAdminSettingsInput
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlUpdateOrgAdminSettingsInput
   */
  releaseChannel?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgshdlUpdateOrgAdminSettingsInput
   */
  useOrgTimezoneForDisplay?: boolean | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlUpdateOrgFeatureFlagsInput
 */
export interface CoreSvcOrgshdlUpdateOrgFeatureFlagsInput {
  /**
   *
   * @type {Array<string>}
   * @memberof CoreSvcOrgshdlUpdateOrgFeatureFlagsInput
   */
  featureFlags?: Array<string> | null;
}
/**
 *
 * @export
 * @interface CoreSvcOrgshdlUpdateOrgSystemSettingsInput
 */
export interface CoreSvcOrgshdlUpdateOrgSystemSettingsInput {
  /**
   *
   * @type {boolean}
   * @memberof CoreSvcOrgshdlUpdateOrgSystemSettingsInput
   */
  allowReleaseChannels?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof CoreSvcOrgshdlUpdateOrgSystemSettingsInput
   */
  customerType?: number;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlUpdateOrgSystemSettingsInput
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CoreSvcOrgshdlUpdateOrgSystemSettingsInput
   */
  trialExpiry?: string | null;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary admin HTTP Handler Run Org Event Migration 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpAdminhdlAdminHTTPHandlerRunOrgEventMigration1: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/migrations/org-event-migration-1`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpHealthhdlHealthHTTPHandlerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary orgs HTTP Handler Bulk Update Orgs
     * @param {CoreSvcOrgshdlBulkUpdateOrgsInput} [coreSvcOrgshdlBulkUpdateOrgsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerBulkUpdateOrgs: async (
      coreSvcOrgshdlBulkUpdateOrgsInput?: CoreSvcOrgshdlBulkUpdateOrgsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/orgs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        coreSvcOrgshdlBulkUpdateOrgsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary orgs HTTP Handler Get
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerGet: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('httpOrgshdlOrgsHTTPHandlerGet', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary orgs HTTP Handler Get For User
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {string} [xActAs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerGetForUser: async (
      pageToken?: string,
      pageSize?: number,
      xActAs?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/orgs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['PageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['PageSize'] = pageSize;
      }

      if (xActAs != null) {
        localVarHeaderParameter['X-Act-As'] = String(xActAs);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary orgs HTTP Handler Update Feature Flags
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgFeatureFlagsInput} [coreSvcOrgshdlUpdateOrgFeatureFlagsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerUpdateFeatureFlags: async (
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgFeatureFlagsInput?: CoreSvcOrgshdlUpdateOrgFeatureFlagsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('httpOrgshdlOrgsHTTPHandlerUpdateFeatureFlags', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/feature-flags`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ifMatch != null) {
        localVarHeaderParameter['if-match'] = String(ifMatch);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        coreSvcOrgshdlUpdateOrgFeatureFlagsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary orgs HTTP Handler Update Org Admin Settings
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgAdminSettingsInput} [coreSvcOrgshdlUpdateOrgAdminSettingsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings: async (
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgAdminSettingsInput?: CoreSvcOrgshdlUpdateOrgAdminSettingsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/admin-settings`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ifMatch != null) {
        localVarHeaderParameter['if-match'] = String(ifMatch);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        coreSvcOrgshdlUpdateOrgAdminSettingsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary orgs HTTP Handler Update Org System Settings
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgSystemSettingsInput} [coreSvcOrgshdlUpdateOrgSystemSettingsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerUpdateOrgSystemSettings: async (
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgSystemSettingsInput?: CoreSvcOrgshdlUpdateOrgSystemSettingsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('httpOrgshdlOrgsHTTPHandlerUpdateOrgSystemSettings', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/system-settings`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ifMatch != null) {
        localVarHeaderParameter['if-match'] = String(ifMatch);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        coreSvcOrgshdlUpdateOrgSystemSettingsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary admin HTTP Handler Run Org Event Migration 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async httpAdminhdlAdminHTTPHandlerRunOrgEventMigration1(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSvcAdminhdlGetOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.httpAdminhdlAdminHTTPHandlerRunOrgEventMigration1(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async httpHealthhdlHealthHTTPHandlerGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSvcHealthhdlGetOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.httpHealthhdlHealthHTTPHandlerGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary orgs HTTP Handler Bulk Update Orgs
     * @param {CoreSvcOrgshdlBulkUpdateOrgsInput} [coreSvcOrgshdlBulkUpdateOrgsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async httpOrgshdlOrgsHTTPHandlerBulkUpdateOrgs(
      coreSvcOrgshdlBulkUpdateOrgsInput?: CoreSvcOrgshdlBulkUpdateOrgsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSvcOrgshdlBulkUpdateOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.httpOrgshdlOrgsHTTPHandlerBulkUpdateOrgs(
        coreSvcOrgshdlBulkUpdateOrgsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary orgs HTTP Handler Get
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async httpOrgshdlOrgsHTTPHandlerGet(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSvcOrgshdlGetOrgOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.httpOrgshdlOrgsHTTPHandlerGet(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary orgs HTTP Handler Get For User
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {string} [xActAs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async httpOrgshdlOrgsHTTPHandlerGetForUser(
      pageToken?: string,
      pageSize?: number,
      xActAs?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSvcOrgshdlGetShortListOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.httpOrgshdlOrgsHTTPHandlerGetForUser(
        pageToken,
        pageSize,
        xActAs,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary orgs HTTP Handler Update Feature Flags
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgFeatureFlagsInput} [coreSvcOrgshdlUpdateOrgFeatureFlagsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async httpOrgshdlOrgsHTTPHandlerUpdateFeatureFlags(
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgFeatureFlagsInput?: CoreSvcOrgshdlUpdateOrgFeatureFlagsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSvcOrgshdlGetOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.httpOrgshdlOrgsHTTPHandlerUpdateFeatureFlags(
        orgId,
        ifMatch,
        coreSvcOrgshdlUpdateOrgFeatureFlagsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary orgs HTTP Handler Update Org Admin Settings
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgAdminSettingsInput} [coreSvcOrgshdlUpdateOrgAdminSettingsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings(
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgAdminSettingsInput?: CoreSvcOrgshdlUpdateOrgAdminSettingsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSvcOrgshdlGetOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings(
        orgId,
        ifMatch,
        coreSvcOrgshdlUpdateOrgAdminSettingsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary orgs HTTP Handler Update Org System Settings
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgSystemSettingsInput} [coreSvcOrgshdlUpdateOrgSystemSettingsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async httpOrgshdlOrgsHTTPHandlerUpdateOrgSystemSettings(
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgSystemSettingsInput?: CoreSvcOrgshdlUpdateOrgSystemSettingsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreSvcOrgshdlGetOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.httpOrgshdlOrgsHTTPHandlerUpdateOrgSystemSettings(
        orgId,
        ifMatch,
        coreSvcOrgshdlUpdateOrgSystemSettingsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary admin HTTP Handler Run Org Event Migration 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpAdminhdlAdminHTTPHandlerRunOrgEventMigration1(options?: any): AxiosPromise<CoreSvcAdminhdlGetOutput> {
      return localVarFp
        .httpAdminhdlAdminHTTPHandlerRunOrgEventMigration1(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpHealthhdlHealthHTTPHandlerGet(options?: any): AxiosPromise<CoreSvcHealthhdlGetOutput> {
      return localVarFp.httpHealthhdlHealthHTTPHandlerGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary orgs HTTP Handler Bulk Update Orgs
     * @param {CoreSvcOrgshdlBulkUpdateOrgsInput} [coreSvcOrgshdlBulkUpdateOrgsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerBulkUpdateOrgs(
      coreSvcOrgshdlBulkUpdateOrgsInput?: CoreSvcOrgshdlBulkUpdateOrgsInput,
      options?: any
    ): AxiosPromise<CoreSvcOrgshdlBulkUpdateOutput> {
      return localVarFp
        .httpOrgshdlOrgsHTTPHandlerBulkUpdateOrgs(coreSvcOrgshdlBulkUpdateOrgsInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary orgs HTTP Handler Get
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerGet(orgId: string, options?: any): AxiosPromise<CoreSvcOrgshdlGetOrgOutput> {
      return localVarFp.httpOrgshdlOrgsHTTPHandlerGet(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary orgs HTTP Handler Get For User
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {string} [xActAs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerGetForUser(
      pageToken?: string,
      pageSize?: number,
      xActAs?: string,
      options?: any
    ): AxiosPromise<CoreSvcOrgshdlGetShortListOutput> {
      return localVarFp
        .httpOrgshdlOrgsHTTPHandlerGetForUser(pageToken, pageSize, xActAs, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary orgs HTTP Handler Update Feature Flags
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgFeatureFlagsInput} [coreSvcOrgshdlUpdateOrgFeatureFlagsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerUpdateFeatureFlags(
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgFeatureFlagsInput?: CoreSvcOrgshdlUpdateOrgFeatureFlagsInput,
      options?: any
    ): AxiosPromise<CoreSvcOrgshdlGetOutput> {
      return localVarFp
        .httpOrgshdlOrgsHTTPHandlerUpdateFeatureFlags(orgId, ifMatch, coreSvcOrgshdlUpdateOrgFeatureFlagsInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary orgs HTTP Handler Update Org Admin Settings
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgAdminSettingsInput} [coreSvcOrgshdlUpdateOrgAdminSettingsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings(
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgAdminSettingsInput?: CoreSvcOrgshdlUpdateOrgAdminSettingsInput,
      options?: any
    ): AxiosPromise<CoreSvcOrgshdlGetOutput> {
      return localVarFp
        .httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings(
          orgId,
          ifMatch,
          coreSvcOrgshdlUpdateOrgAdminSettingsInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary orgs HTTP Handler Update Org System Settings
     * @param {string} orgId
     * @param {string} [ifMatch]
     * @param {CoreSvcOrgshdlUpdateOrgSystemSettingsInput} [coreSvcOrgshdlUpdateOrgSystemSettingsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    httpOrgshdlOrgsHTTPHandlerUpdateOrgSystemSettings(
      orgId: string,
      ifMatch?: string,
      coreSvcOrgshdlUpdateOrgSystemSettingsInput?: CoreSvcOrgshdlUpdateOrgSystemSettingsInput,
      options?: any
    ): AxiosPromise<CoreSvcOrgshdlGetOutput> {
      return localVarFp
        .httpOrgshdlOrgsHTTPHandlerUpdateOrgSystemSettings(
          orgId,
          ifMatch,
          coreSvcOrgshdlUpdateOrgSystemSettingsInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary admin HTTP Handler Run Org Event Migration 1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public httpAdminhdlAdminHTTPHandlerRunOrgEventMigration1(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .httpAdminhdlAdminHTTPHandlerRunOrgEventMigration1(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary health HTTP Handler Get
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public httpHealthhdlHealthHTTPHandlerGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .httpHealthhdlHealthHTTPHandlerGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary orgs HTTP Handler Bulk Update Orgs
   * @param {CoreSvcOrgshdlBulkUpdateOrgsInput} [coreSvcOrgshdlBulkUpdateOrgsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public httpOrgshdlOrgsHTTPHandlerBulkUpdateOrgs(
    coreSvcOrgshdlBulkUpdateOrgsInput?: CoreSvcOrgshdlBulkUpdateOrgsInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .httpOrgshdlOrgsHTTPHandlerBulkUpdateOrgs(coreSvcOrgshdlBulkUpdateOrgsInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary orgs HTTP Handler Get
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public httpOrgshdlOrgsHTTPHandlerGet(orgId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .httpOrgshdlOrgsHTTPHandlerGet(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary orgs HTTP Handler Get For User
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {string} [xActAs]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public httpOrgshdlOrgsHTTPHandlerGetForUser(
    pageToken?: string,
    pageSize?: number,
    xActAs?: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .httpOrgshdlOrgsHTTPHandlerGetForUser(pageToken, pageSize, xActAs, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary orgs HTTP Handler Update Feature Flags
   * @param {string} orgId
   * @param {string} [ifMatch]
   * @param {CoreSvcOrgshdlUpdateOrgFeatureFlagsInput} [coreSvcOrgshdlUpdateOrgFeatureFlagsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public httpOrgshdlOrgsHTTPHandlerUpdateFeatureFlags(
    orgId: string,
    ifMatch?: string,
    coreSvcOrgshdlUpdateOrgFeatureFlagsInput?: CoreSvcOrgshdlUpdateOrgFeatureFlagsInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .httpOrgshdlOrgsHTTPHandlerUpdateFeatureFlags(orgId, ifMatch, coreSvcOrgshdlUpdateOrgFeatureFlagsInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary orgs HTTP Handler Update Org Admin Settings
   * @param {string} orgId
   * @param {string} [ifMatch]
   * @param {CoreSvcOrgshdlUpdateOrgAdminSettingsInput} [coreSvcOrgshdlUpdateOrgAdminSettingsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings(
    orgId: string,
    ifMatch?: string,
    coreSvcOrgshdlUpdateOrgAdminSettingsInput?: CoreSvcOrgshdlUpdateOrgAdminSettingsInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings(
        orgId,
        ifMatch,
        coreSvcOrgshdlUpdateOrgAdminSettingsInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary orgs HTTP Handler Update Org System Settings
   * @param {string} orgId
   * @param {string} [ifMatch]
   * @param {CoreSvcOrgshdlUpdateOrgSystemSettingsInput} [coreSvcOrgshdlUpdateOrgSystemSettingsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public httpOrgshdlOrgsHTTPHandlerUpdateOrgSystemSettings(
    orgId: string,
    ifMatch?: string,
    coreSvcOrgshdlUpdateOrgSystemSettingsInput?: CoreSvcOrgshdlUpdateOrgSystemSettingsInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .httpOrgshdlOrgsHTTPHandlerUpdateOrgSystemSettings(
        orgId,
        ifMatch,
        coreSvcOrgshdlUpdateOrgSystemSettingsInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
