<template>
  <v-layout row wrap ma-1>
    <v-flex offset-xs11 xs1>
      <AddUser v-on:complete="refresh" v-if="checkScope(scopeLiterals.UsersCreate)" />
    </v-flex>
    <v-flex xs12>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="invites"
        :pagination.sync="pagination"
        select-all
        item-key="id"
        :loading="isLoading ? 'accent' : false"
        class="elevation-3"
      >
        <v-progress-linear slot="progress" color="accent" height="2" indeterminate></v-progress-linear>
        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable text-xs-' + header.align,
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr :active="props.selected" @click="props.selected = !props.selected">
            <td>{{ props.item.userName }}</td>
            <td>{{ props.item.email }}</td>
            <!-- <td class="text-xs-right">
              <v-btn icon @click.stop="">
                <v-icon>close</v-icon>
              </v-btn>
            </td> -->
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

import AddUser from './AddUser';

export default {
  props: [],
  components: {
    AddUser: AddUser,
  },
  data() {
    return {
      invites: [],
      selected: [],
      isLoading: 0,
      pagination: {
        sortBy: 'email',
      },
      headers: [
        {
          text: 'name',
          align: 'left',
          value: 'userName',
        },
        {
          text: 'email',
          align: 'left',
          value: 'email',
        },
      ],
    };
  },
  apollo: {
    invites: {
      // pollInterval: 5000,
      query: gql`
        query GetOrgInvites($id: ID!) {
          org(id: $id) {
            id
            invites {
              id
              userName
              email
              byUserId
            }
          }
        }
      `,
      update(data) {
        return data.org.invites ? data.org.invites : [];
      },
      variables() {
        // Use vue reactive properties here
        return {
          id: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$apollo.queries.invites.refetch();
    },
  },
};
</script>
