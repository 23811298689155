




































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import { TaxCoin } from './types';

@Component
export default class AddTaxCoin extends BaseVue {
  @Prop({ required: true })
  public readonly editItem!: TaxCoin | null;

  dialog = false;
  coin = '';
  wrappedCoins = [''];
  valid = false;
  rules = {
    required: (v: string) => !!v || 'Field is required',
  };

  @Ref()
  public readonly addForm!: { resetValidation(): void; validate(): boolean };

  get canSave() {
    if (!this.coin) return false;
    if (!this.wrappedCoins.length) return false;
    return true;
  }

  async save() {
    const newTaxConfig = { ...this.$store.state.currentOrg.taxConfig };
    if (!newTaxConfig.wrappingTreatments) {
      newTaxConfig.wrappingTreatments = {};
    }
    newTaxConfig.wrappingTreatments[this.coin] = this.wrappedCoins;
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      taxConfig: {
        capitalizeFees: newTaxConfig.capitalizeTradingFees,
        wrappingTreatments: newTaxConfig.wrappingTreatments,
      },
    };

    const resp = await this.$apollo.mutate({
      // Query
      mutation: gql`
        mutation updateOrg($orgId: ID!, $taxConfig: TaxConfigInput!) {
          updateOrg(orgId: $orgId, taxConfig: $taxConfig) {
            id
            taxConfig {
              wrappingTreatments
            }
          }
        }
      `,
      // Parameters
      variables: vars,
    });
    if (resp.errors) {
      this.showErrorSnackbar('Problem: ' + resp.errors.join('<br />'));
    }

    if (resp.data && resp.data.updateOrg) {
      this.showSuccessSnackbar('Coin is added!');
      this.coin = '';
      this.wrappedCoins = [''];
      this.dialog = false;
      this.$emit('complete');
    }
  }

  onAddCoin() {
    this.wrappedCoins.push('');
  }

  deleteCoin(index: number) {
    this.wrappedCoins.splice(index, 1);
  }

  onHide() {
    this.dialog = false;
    this.coin = '';
    this.wrappedCoins = [''];
    this.addForm.resetValidation();
    this.$emit('close');
  }

  @Watch('editItem')
  onEditItemChanged() {
    if (!this.editItem) return;
    this.dialog = true;
    this.coin = this.editItem.coin;
    this.wrappedCoins = this.editItem.wrappedCoins;
  }
}
