<template>
  <v-layout row wrap class="pa-1">
    <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="4000" :top="true">
      {{ snackbarText }}
    </v-snackbar>
    <v-flex xs12 class="elevation-1">
      <v-data-table
        :headers="headers"
        :items="wallets"
        :pagination.sync="pagination"
        item-key="id"
        :hide-actions="wallets && wallets.length <= 5"
        :loading="isLoading ? 'accent' : false"
        no-data-text=""
        style="background-color: white"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column text-capitalize sortable text-xs-' + header.align,
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon small>arrow_upward</v-icon>
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr>
            <td class="text-xs-left short-name pt-1 ma-0" style="vertical-align: text-top">
              {{ props.item.name }}
            </td>
            <td>
              <v-menu offset-y>
                <v-btn slot="activator" color="secondary" dark :small="true" colo class="secondary">
                  {{ $tc('_action', 2) }}
                  <i class="material-icons"> arrow_drop_down </i>
                </v-btn>
                <v-list>
                  <v-list-tile @click="deleteWallet(props.item)" v-if="checkScope(scopeLiterals.WalletsDelete)">
                    <v-list-tile-title>{{ $t('_delete') }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: [],
  data() {
    return {
      pagination: {
        sortBy: 'name',
      },
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      headers: [
        {
          text: this.$tc('_wallet', 2),
          align: 'left',
          value: 'name',
        },
        // {
        //   text: this.$tc('_action', 2),
        //   align: 'left',
        //   value: 'actions',
        // },
      ],
      wallets: [],
      isLoading: 0,
    };
  },
  apollo: {
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            type
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    refresh() {
      this.$apollo.queries.wallets.refetch();
    },
    deleteWallet(wallet) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        systemJobId: 'delete-wallet',
        walletId: wallet.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation (
              $orgId: ID!
              $systemJobId: String!
              $action: String
              $walletId: String
              $startSEC: String
              $endSEC: String
            ) {
              runSystemJob(
                orgId: $orgId
                systemJobId: $systemJobId
                action: $action
                walletId: $walletId
                startSEC: $startSEC
                endSEC: $endSEC
              )
            }
          `,
          variables: vars,
        })
        .then((res) => {
          console.log(res);
          // snackbar
          if (res.data.runSystemJob) {
            this.snackbar = true;
            this.snackbarText = `Wallet deletion system job submitted with job id ${res.data.runSystemJob}`;
          } else {
            this.snackbar = true;
            this.snackbarText = 'Error deleting wallet: ' + res.data.runSystemJob.errors;
            this.snackbarColor = 'error';
          }
          this.refresh();
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = 'Error deleting wallet: ' + err;
          this.snackbarColor = 'error';
          console.log(err);
        });
    },
  },
  components: {},
};
</script>
<style scoped>
.short-name {
  white-space: nowrap;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.short-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table tr td {
  font-size: 0.9em;
}
.number-cell:hover {
  background-color: #f9f9f9;
}
</style>
