<template>
  <div class="tw-shadow-md" color="white">
    <div v-if="isLoading > 0">
      <v-progress-linear indeterminate color="green"></v-progress-linear>
    </div>
    <div class="tw-p-1" v-else>
      <div class="tw-p-6 tw-px-12 tw-grid tw-gap-2">
        <UiCheckbox
          :label="$tc('_collapseAcrossWallets', 1)"
          :value="collapseAcrossWallets"
          :readonly="false"
          :searchable="true"
          @input="(value) => updateCollapse(value)"
        ></UiCheckbox>
        <UiCheckbox
          :label="$tc('_allowTxnInference', 1)"
          :value="allowTxnInference"
          :readonly="false"
          :searchable="true"
          @input="(value) => updateAllow(value)"
        ></UiCheckbox>
        <UiSelect2
          :label="$tc('_network', 1)"
          :value="selectedNetwork"
          :items="networks"
          :searchable="true"
          @input="handleNetworkChange"
        ></UiSelect2>
        <UiSelect2
          labelField="name"
          label="Network Contact"
          :items="contacts"
          :searchable="true"
          :value="findNetworkContactById(networksToContactIds[selectedNetwork.id])"
          @input="handleNetworkContactChange"
        ></UiSelect2>
        <UiSelect2
          labelField="displayName"
          label="Default Fee Category"
          :items="categories"
          :searchable="true"
          :value="findCategoryById(networksToCategoryIds[selectedNetwork.id])"
          @input="handleFeeCategoryChange"
        ></UiSelect2>
        <UiSelect2
          labelField="displayName"
          label="Default Accounts Receivable Category"
          :items="categories"
          :searchable="true"
          :value="findCategoryById(defaultAccountsReceivableCategoryId)"
          @input="handleAccountsReceivableChange"
        ></UiSelect2>
        <UiSelect2
          labelField="displayName"
          label="Default Accounts Payable Category"
          :items="categories"
          :searchable="true"
          :value="findCategoryById(defaultAccountsPayableCategoryId)"
          @input="handleAccountsPayableChange"
        ></UiSelect2>
        <div class="ma-3"></div>
        <div class="form-btn">
          <UiButton @click.native="save" color="primary"> Save </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import { getAccountingProviderIcon } from '@/utils/accountingProviders';

import { Networks } from '../../contracts/networks';
import UiButton from '../ui/UiButton.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';
import UiSelect2 from '../ui/UiSelect2.vue';

export default {
  props: [],
  data() {
    const accConfig = this.$store.state.currentOrg.accountingConfig;
    return {
      networks: Object.values(Networks),
      selectedNetwork: { id: 'eth', label: 'eth', icon: undefined },
      networksToContactIds:
        accConfig && accConfig.networkContactIds ? this.mapContactIdsToNetworks(accConfig.networkContactIds) : {},
      networksToCategoryIds:
        accConfig && accConfig.defaultFeeCategoryIds
          ? this.mapCategoryIdsToNetworks(accConfig.defaultFeeCategoryIds)
          : {},
      isLoading: false,
      networkContactId: accConfig && accConfig.networkContactIds ? accConfig.networkContactIds.eth : '',
      defaultFeeCategoryId: accConfig && accConfig.defaultFeeCategoryId ? accConfig.defaultFeeCategoryId : '',
      allowTxnInference: accConfig && accConfig.allowTxnInference ? accConfig.allowTxnInference : false,
      collapseAcrossWallets: accConfig && accConfig.collapseAcrossWallets ? accConfig.collapseAcrossWallets : false,
      defaultAccountsReceivableCategoryId:
        accConfig && accConfig.defaultAccountsReceivableCategoryId ? accConfig.defaultAccountsReceivableCategoryId : '',
      defaultAccountsPayableCategoryId:
        accConfig && accConfig.defaultAccountsPayableCategoryId ? accConfig.defaultAccountsPayableCategoryId : '',
    };
  },
  computed: {
    contacts() {
      return this.$store.getters['contacts/ENABLED_CONTACTS'];
    },
    categories() {
      return this.$store.getters['categories/ENABLE_CATEGORIES'].map((cat) => ({
        ...cat,
        displayName: `${cat.code} | ${cat.name}`,
      }));
    },
  },
  methods: {
    mapCategoryIdsToNetworks(categoryList) {
      const object = {};
      categoryList.map((listItem) => {
        object[listItem.blockchain] = listItem.categoryId;
      });
      return object;
    },
    mapContactIdsToNetworks(contactList) {
      const object = {};
      contactList.map((listItem) => {
        return (object[listItem.blockchain] = listItem.contactId);
      });
      return object;
    },
    handleNetworkChange(value) {
      this.selectedNetwork = value;
    },
    handleNetworkContactChange(value) {
      this.networksToContactIds = { ...this.networksToContactIds, [this.selectedNetwork.id]: value.id };
    },
    findNetworkContactById(id) {
      return this.contacts.find((contact) => contact.id === id);
    },
    handleFeeCategoryChange(value) {
      this.networksToCategoryIds = { ...this.networksToCategoryIds, [this.selectedNetwork.id]: value.id };
    },
    findCategoryById(id) {
      return this.categories.find((contact) => contact.id === id);
    },
    handleAccountsReceivableChange(value) {
      this.defaultAccountsReceivableCategoryId = value.id;
    },
    handleAccountsPayableChange(value) {
      this.defaultAccountsPayableCategoryId = value.id;
    },
    getRemoteID(item) {
      const split = item.id.split('.');
      split.shift();
      return split.join('.');
    },
    getProviderIcon(provider) {
      return getAccountingProviderIcon(provider);
    },
    save() {
      this.isLoading = true;
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        networkContact: {
          blockchain: 'eth',
          contactId: 'deprecated',
        },
        networkContactIds: Object.entries(this.networksToContactIds).map((entry) => {
          return { blockchain: entry[0], contactId: entry[1] };
        }),
        defaultFeeCategoryId: this.defaultFeeCategoryId,
        defaultFeeCategoryIds: Object.entries(this.networksToCategoryIds).map((entry) => {
          return { blockchain: entry[0], categoryId: entry[1] };
        }),
        allowTxnInference: this.allowTxnInference,
        defaultAccountsReceivableCategoryId: this.defaultAccountsReceivableCategoryId,
        defaultAccountsPayableCategoryId: this.defaultAccountsPayableCategoryId,
        collapseAcrossWallets: this.collapseAcrossWallets,
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation (
              $orgId: ID!
              $networkContact: NetworkContactInput
              $defaultFeeCategoryId: String
              $allowTxnInference: Boolean
              $collapseAcrossWallets: Boolean
              $defaultAccountsReceivableCategoryId: String
              $defaultAccountsPayableCategoryId: String
              $networkContactIds: [NetworkContactInput]
              $defaultFeeCategoryIds: [NetworkFeeCategoryInput]
            ) {
              updateOrgAccountingConfig(
                orgId: $orgId
                networkContact: $networkContact
                defaultFeeCategoryId: $defaultFeeCategoryId
                allowTxnInference: $allowTxnInference
                collapseAcrossWallets: $collapseAcrossWallets
                defaultAccountsReceivableCategoryId: $defaultAccountsReceivableCategoryId
                defaultAccountsPayableCategoryId: $defaultAccountsPayableCategoryId
                networkContactIds: $networkContactIds
                defaultFeeCategoryIds: $defaultFeeCategoryIds
              ) {
                id
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(async () => {
          this.showSuccessSnackbar('Successfully updated.');
          await this.$store.dispatch('updateOrgs');
          this.isLoading = false;
        });
    },
    updateAllow(value) {
      this.allowTxnInference = value;
    },
    updateCollapse(value) {
      this.collapseAcrossWallets = value;
    },
    getUTCLocaleDateString(dateTimeSec) {
      // get a locale-specific date string from a Unix timestamp, without converting time zones
      const date = new Date(dateTimeSec * 1000);
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();
      return new Date(year, month, day).toLocaleDateString();
    },
  },
  watch: {
    hardClose(value) {
      if (value) {
        this.hardCloseDate = this.hardCloseDateOld ?? new Date();
      } else {
        this.hardCloseDateOld = this.hardCloseDate;
        this.hardCloseDate = null;
      }
    },
  },
  components: { UiCheckbox, UiSelect2, UiButton },
};
</script>
