export enum Networks {
  Bitcoin = 'btc',
  BitcoinCash = 'bch',
  BitcoinGold = 'btg',
  Ethereum = 'eth',
  Dash = 'dash',
  EOS = 'eos',
  Litecoin = 'ltc',
  Stellar = 'xlm',
  Zcash = 'zec',
  Tron = 'trx',
  Harmony = 'one',
  Solana = 'sol',
  Polkadot = 'dot',
  Tezos = 'xtz',
  Secret = 'scrt',
  Avalanche = 'avax',
  AvalancheC = 'avaxc',
  Aion = 'aion',
  Icon = 'icx',
  Atom = 'atom',
  Band = 'band',
  Kava = 'kava',
  IRIS = 'iris',
  Filecoin = 'fil',
  Oasis = 'rose',
  Near = 'near',
  BSV = 'bsv',
  Verge = 'xvg',
  Polygon = 'polygon',
  Celo = 'celo',
  Etc = 'etc',
  Raven = 'rvn',
  Doge = 'doge',
  Cardano = 'ada',
  Mina = 'mina',
  Flow = 'flow',
  Kusama = 'ksm',
  Hedera = 'hbar',
  Ripple = 'xrp',
  Omni = 'omni',
  Bsc = 'bsc',
  HecoChain = 'heco',
  Terra = 'terra',
  Klayton = 'klay',
  Algorand = 'algo',
  Loopring = 'lrc',
  Casper = 'casper',
  Stacks = 'stx',
  Bnb = 'bnb',
  Cosmos = 'cosmos',
  Fantom = 'ftm',
  Gnosis = 'gnosis',
  Optimism = 'op',
  Moonbeam = 'moon',
  Icp = 'icp',
  Iost = 'iost',
  Iota = 'iota',
  Immutable = 'imx',
  EthPOW = 'ethw',
  Ronin = 'ronin',
  Shimmer = 'shimmer',
  Neo = 'neo',
  Qtum = 'qtum',
  Songbird = 'sgb',
  Vchain = 'vet',
  Aurora = 'aurora',
  Aptos = 'apt',
  Ontology = 'ont',
  Sia = 'sc',
  Nem = 'xem',
  Monero = 'xmr',
  Arbitrum = 'arb',
  Osmosis = 'osmo',
  Agoric = 'bld',
  Aioz = 'aioz',
  Akash = 'akt',
  AssetMantle = 'mntl',
  Axelar = 'axl',
  BeeZee = 'bze',
  BitCanna = 'bcna',
  Bitsong = 'btsg',
  Bostrom = 'boot',
  Canto = 'canto',
  Cronos = 'cro',
  Injective = 'inj',
  Fetchai = 'fet',
  Ankr = 'ankr',
  Juno = 'juno',
  Kadena = 'kda',
  OktChain = 'okt',
  Horizen = 'zen',
  Zilliqa = 'zil',
  Kaspa = 'kas',
  Holo = 'hot',
  Audius = 'audio',
  Astar = 'astr',
  Beldex = 'bdx',
  Helium = 'hnt',
  Regen = 'regen',
  Umee = 'umee',
  Evmos = 'evmos',
  Moonriver = 'movr',
  Sentinel = 'dvpn',
  Persistence = 'xprt',
}
