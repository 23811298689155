



























































import gql from 'graphql-tag';
import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';

import AddTaxCoin from './AddTaxCoin.vue';
import { TaxCoin } from './types';

@Component({
  components: {
    AddTaxCoin: AddTaxCoin,
  },
  apollo: {
    taxes: {
      // pollInterval: 5000,
      query: gql`
        query GetOrgTaxes($id: ID!) {
          org(id: $id, allowCache: false) {
            id
            taxConfig {
              capitalizeTradingFees
              wrappingTreatments
            }
          }
        }
      `,
      update(data) {
        const wrappingTreatments = data.org.taxConfig?.wrappingTreatments;
        if (!wrappingTreatments) {
          return [];
        }
        const wrappingArray: any[] = [];
        Object.entries(wrappingTreatments).forEach(([key, value]) => {
          wrappingArray.push({
            coin: key,
            wrappedCoins: value,
          });
        });
        return wrappingArray;
      },
      variables() {
        // Use vue reactive properties here
        return {
          id: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      fetchPolicy: 'network-only',
    },
  },
})
export default class Taxes extends BaseVue {
  taxes = [];
  selected = [];
  isLoading = 0;
  pagination = {
    sortBy: 'email',
  };

  headers = [
    {
      text: 'coin',
      align: 'left',
      value: 'coin',
    },
    {
      text: 'wrapped coins',
      align: 'left',
      value: 'wrappedCoins',
    },
  ];

  editItem: TaxCoin | null = null;

  mounted() {
    this.refresh();
  }

  refresh() {
    this.$apollo.queries.taxes.refetch();
  }

  close() {
    if (this.editItem) {
      this.editItem = null;
    }
  }

  editCoin(item: TaxCoin) {
    this.editItem = item;
  }

  async deleteCoin(item: TaxCoin) {
    if (!confirm('Want to delete the coin?')) {
      return;
    }

    const newTaxConfig = { ...this.$store.state.currentOrg.taxConfig };
    delete newTaxConfig.wrappingTreatments[item.coin];
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      taxConfig: {
        capitalizeFees: newTaxConfig.capitalizeTradingFees,
        wrappingTreatments: newTaxConfig.wrappingTreatments,
      },
    };

    const resp = await this.$apollo.mutate({
      // Query
      mutation: gql`
        mutation updateOrg($orgId: ID!, $taxConfig: TaxConfigInput!) {
          updateOrg(orgId: $orgId, taxConfig: $taxConfig) {
            id
            taxConfig {
              capitalizeTradingFees
              wrappingTreatments
            }
          }
        }
      `,
      // Parameters
      variables: vars,
    });
    if (resp.errors) {
      this.showErrorSnackbar('Problem: ' + resp.errors.join('<br />'));
    }
  }
}
